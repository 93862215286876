import { Navigate } from 'react-router-dom';
import FetchUser from "../../views/Auth";

const AdminRoute = ({ children }) => {
    const user = FetchUser;
    const groups = user?.signInUserSession.accessToken.payload['cognito:groups'];
    const isAdmin = groups?.includes("Admin");

    return isAdmin ? children : <Navigate to="/login" />;
}

export default AdminRoute;
