import { useNavigate } from "react-router-dom";
import './style.css';
import {useTranslation} from "react-i18next";
import getConfigForDomain from "../../helpers/getConfigForDomain";
function NoMatch() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const appConfig = getConfigForDomain(process.env.REACT_APP_DOMAIN);

    return(
        <div className="fourofourContainer">
            <h1>{t("404 Not Found!")}</h1>
            <button className="submitButton" onClick={() => navigate(-1)} style={{backgroundColor: appConfig.palette['main-color']}}>{t('Go Back')}</button>
        </div>
    )
}

export default NoMatch
