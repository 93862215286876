import {Routes, Route, Outlet, Link, useLocation} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import './index.css';
import Login from "../../views/Login";
import Logout from "../../views/Logout";
import ResetPassword from "../../views/ResetPassword";
import NoMatch from "../../views/404";
import CreateUser from "../../views/Register";
import Home from "../../views/Home";
import UserProfile from "../../views/UserProfile";
import noAvatar from '../../public/images/user-logo.svg';
import FetchUser from "../../views/Auth/index";
import ExpertProfile from "../../views/ExpertProfile";
import Subscribe from "../../views/Subscribe";
import PrivateRoute from "../PrivateRoute";
import SuccessfulPayment from "../../views/SuccessfulPayment";
import FailedPayment from "../../views/FailedPayment";
import Experts from "../../views/Experts";
import AdminRoute from "../AdminRoute";
import AdminPage from "../../views/AdminPage";
import {useTranslation} from "react-i18next";
import getConfigForDomain from "../../helpers/getConfigForDomain";
import Footer from "../Footer";
import PremiumRestrictRoute from "../PremiumRestrictRoute";
import TermsAndConditions from "../../views/TermsAndConditions";
import {useDispatch} from "react-redux";
import {initializeDataStore} from "../../store/actions/dataStoreActions";
import AboutUs from "../../views/AboutUs";

function NavBar() {
    return (
        <Routes>
            <Route path="/" element={<Layout/>}>
                <Route index element={<Home/>}/>
                <Route path="login" element={<Login/>}/>
                <Route path="logout" element={<Logout/>}/>
                <Route path="reset" element={<ResetPassword/>}/>
                <Route path="register" element={<CreateUser/>}/>
                <Route path="*" element={<NoMatch/>}/>
                <Route path="user/:id" element={<PrivateRoute><UserProfile/></PrivateRoute>}/>
                <Route path="admin" element={<AdminRoute><AdminPage/></AdminRoute>}/>
                <Route path="expert/:name" element={<ExpertProfile/>}/>
                <Route path="subscribe" element={<PremiumRestrictRoute><Subscribe/></PremiumRestrictRoute>}/>
                <Route path="checkout-successful" element={<SuccessfulPayment/>}/>
                <Route path="checkout-failed" element={<FailedPayment/>}/>
                <Route path="experts" element={<Experts/>}/>
                <Route path="terms-and-conditions" element={<TermsAndConditions/>}/>v
                <Route path="about-us" element={<AboutUs/>}/>v
            </Route>
        </Routes>
    )
}

function Layout() {
    const dispatch = useDispatch();
    const appConfig = getConfigForDomain(process.env.REACT_APP_DOMAIN);
    const {t} = useTranslation();
    const user = FetchUser;
    let location = useLocation();
    location = location && location.pathname ? location.pathname.replace(/\//g, '') : '';
    const [showModal, setShowModal] = useState(false);
    const handleClick = () => setShowModal(!showModal);
    const subBoxRef = useRef(null);

    useEffect(() => {
        // Dispatch initializeDataStore action on component mount
        dispatch(initializeDataStore());
    }, [dispatch]);

    // Add a click event listener to the document
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (subBoxRef.current && !subBoxRef.current.contains(event.target)) {
                setShowModal(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    return (
        <>
            <header style={{
                backgroundColor: appConfig.palette["app-color"],
                borderColor: appConfig.palette["border-color"]
            }}>
                <div
                    className={appConfig.headerStyle === 'fluid' ? "NavContainer container" : "NavContainer"}>
                    <Link to="" className={appConfig.headerStyle === 'fluid' ? "logo fluid" : "logo"}><img
                        src={appConfig.logo} alt="Picksensei logo"/></Link>
                    <div className={appConfig && appConfig.headerStyle === 'fluid' ? "avatarBox fluid" : "avatarBox"}>
                        {user ? (
                            <>
                                {user.attributes['custom:membership'] !== 'premium' && (
                                    <Link to="/subscribe" className="subscribeButton">{t("Subscribe")}</Link>
                                )}
                                <img src={noAvatar} alt="avatar" className="avatar" ref={subBoxRef}
                                     onClick={handleClick}/>
                                {showModal ? (
                                    <div className="subBox" style={{backgroundColor: appConfig.palette['main-color']}}>
                                    <span>{t("Welcome")}
                                        <Link
                                            to={'user/' + user.username}> {user.attributes.email}</Link>!</span>
                                        <span><Link
                                            to={'user/' + user.username}>{t("Profile")}</Link></span>
                                        <span><Link to="/logout">{t("Log out")}</Link></span>
                                    </div>
                                ) : null}
                            </>
                        ) : (
                            <>
                                <Link to="/login" className="loginButton"
                                      style={{backgroundColor: appConfig.palette['main-color']}}>{t("Log in")}</Link>
                                <Link to="/subscribe" className="subscribeButton">{t("Subscribe")}</Link>
                            </>
                        )}
                    </div>
                </div>
            </header>
            <main className={location} style={{backgroundColor: appConfig.palette['background-color']}}>
                <div className="container" style={{backgroundColor: appConfig.palette['container-color']}}>
                    <Outlet/>
                </div>
            </main>
            <Footer/>
        </>
    )
}

export default NavBar
