import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import Title from '../Title';
import ToggleButton from '../ToggleButton';
import PickCard from '../PickCard';
import './index.css';
import isObjectEmpty from '../../helpers/isObjectEmpty';
import useFilterPicks from '../../hooks/useFilterPicks';
import { t } from "i18next";
import getConfigForDomain from "../../helpers/getConfigForDomain";

function PicksLayout({ sportName, picks, user, isHistory }) {
    const allLeagues = useSelector((state) => state.leagues);
    const filterPicks = useFilterPicks(picks);
    const appConfig = getConfigForDomain(process.env.REACT_APP_DOMAIN);
    const userIsPremium = user && user.attributes['custom:membership'] === 'premium';

    const [activeButton, setActiveButton] = useState('All');
    const [relatedPicks, setRelatedPicks] = useState(picks);
    const [leagues, setLeagues] = useState({});

    useEffect(() => {
        setRelatedPicks(filterPicks);
        updateFilteredLeagues(filterPicks);
        setActiveButton('All');
    }, [filterPicks]);

    const updateFilteredLeagues = useCallback((picks) => {
        const leagueSportMapping = {};

        if (!isObjectEmpty(picks)) {
            for (const entry of picks) {
                const { league: { name: leagueName }, sport: { name: sportName }, premium } = entry;

                if (!leagueSportMapping[sportName]) {
                    leagueSportMapping[sportName] = new Map();
                }

                if (!leagueSportMapping[sportName].has(leagueName)) {
                    leagueSportMapping[sportName].set(leagueName, { total: 0, premiumCount: 0 });
                }

                leagueSportMapping[sportName].get(leagueName).total += 1;

                if (premium) {
                    leagueSportMapping[sportName].get(leagueName).premiumCount += 1;
                }
            }

            const filteredLeagues = Object.entries(leagueSportMapping).reduce((acc, [sport, leaguesMap]) => {
                acc[sport] = Array.from(leaguesMap.entries()).reduce((leagueAcc, [leagueName, { total, premiumCount }]) => {
                    leagueAcc[leagueName] = {
                        total,
                        premiumCount,
                        isPremium: total > 0 && total === premiumCount
                    };
                    return leagueAcc;
                }, {});
                return acc;
            }, {});

            if (!isObjectEmpty(filteredLeagues)) {
                setLeagues(filteredLeagues);
            } else {
                setLeagues({ [sportName]: allLeagues[sportName] });
            }
        }
    }, [allLeagues, sportName]);

    const handleButtonClick = useCallback((buttonId) => {
        setActiveButton(buttonId);
        if (buttonId === 'All') {
            setRelatedPicks(filterPicks);
        } else {
            setRelatedPicks(filterPicks.filter(item => item.league.name === buttonId));
        }
    }, [filterPicks]);

    return (
        <>
            {relatedPicks.length > 0 && (
                <div className="picksLayout">
                    <Title text={sportName} showIcon={true} headingLevel="2" />
                    {!isObjectEmpty(leagues) && Object.keys(leagues[sportName] || {})?.length > 1 && (
                        <>
                            <ToggleButton
                                isActive={activeButton === 'All'}
                                text="All"
                                onClick={() => handleButtonClick('All')}
                            />
                            {Object.keys(leagues[sportName]).map((leagueName) => (
                                (isHistory || (leagues[sportName][leagueName].isPremium && userIsPremium) ||
                                    !leagues[sportName][leagueName].isPremium) ? (
                                    <ToggleButton
                                        key={leagueName}
                                        isActive={activeButton === leagueName}
                                        text={leagueName}
                                        onClick={() => handleButtonClick(leagueName)}
                                    />
                                ) : null
                            ))}
                        </>
                    )}
                    {relatedPicks.map((item) =>
                        (isHistory || userIsPremium || !item.premium) ? (
                            <PickCard key={item.id} item={item} isDummy={false} />
                        ) : null
                    )}
                    {(!user || !userIsPremium) && !isHistory ? (
                        <>
                            <PickCard item={{
                                league: { name: "Nice try mate!" },
                                eventDate: "2023-08-29T15:30:00Z",
                                eventName: "Dummy Event",
                                persona: { name: "Dummy" },
                                won: true,
                                odds: 2.5,
                                bet: "$50"
                            }} isDummy={true} />
                            <Link to="/experts" style={{
                                color: appConfig.palette['main-color'],
                                margin: "16px auto",
                                display: 'block',
                                width: 'fit-content'
                            }}>{t('Experts records')}</Link>
                        </>
                    ) : null}
                </div>
            )}
        </>
    );
}

export default PicksLayout;
