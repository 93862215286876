import "./index.css";
import {useTranslation} from "react-i18next";
import getConfigForDomain from "../../helpers/getConfigForDomain";

function PickCard({ item, isDummy, isWidget= false }) {
    const appConfig = getConfigForDomain(process.env.REACT_APP_DOMAIN);
    const { t } = useTranslation();
    const {
        league,
        eventDate,
        eventName,
        persona,
        won,
        odds,
        bet,
        oddsOperator
    } = item;

    const formatEventDate = (eventDate) => {
        return eventDate
            ? new Date(eventDate).toLocaleDateString("en-GB", {
                day: "numeric",
                month: "numeric",
                year: "numeric"
            })
            : null;
    };

    const eventClass = won.length ? "eventName won" : "eventName";
    const oddClass = won.length ? "oddName past" : "oddName";

    // Conditionally import the Link component based on your condition
    const Link = !isWidget
        ? require('react-router-dom').Link
        : null;

    const renderPersonaLink = () => {
        if (persona?.name) {
            if(isWidget) {
                return (
                    <span className="personaName blackText">
                        {persona.name}
                    </span>
                )
            } else {
                return (
                    <Link to={`expert/${persona.name.toLowerCase()}`} className="personaName blackText">
                        {persona.name}
                    </Link>
                );
            }
        }
        return null;
    };

    return (
        <div className={isWidget ? 'pickCard widget' : 'pickCard'}>
            <div className={isDummy ? 'pickCardRow blurry' : 'pickCardRow'}>
                {league?.name && (
                    <strong className="tournamentName blackText">{league.name}</strong>
                )}
                <span className="grayText">{formatEventDate(eventDate) + ' '}
                    {won.length > 0 && renderPersonaLink()}
                </span>
            </div>
            <div className={isDummy ? 'pickCardRow blurry' : 'pickCardRow'}>
                <strong className={eventClass}>{eventName}</strong>
                {!won.length && renderPersonaLink()}
                {won.length > 0 && (
                    <span className={oddClass}>
                        {oddsOperator ? `${odds} - ${oddsOperator}` : odds}
                    </span>
                )}
            </div>
            <div className={isDummy ? 'pickCardRow blurry' : 'pickCardRow'}>
                <div className="bet">
                    <span className={won.length ? "predictName won" : "grayText"}>
                        {t("Bet") + ": "}
                    </span>
                    <strong
                        className={won.length ? "predictName won" : "predictName blackText"}
                    >
                        {bet}
                    </strong>
                </div>
                {!won.length && (
                    <span className={oddClass} style={{backgroundColor: appConfig.palette['main-color']}}>
                        {oddsOperator ? `${odds} - ${oddsOperator}` : odds}
                    </span>
                )}
                {won.length > 0 && (
                    <span className={won[0] === "WON" ? "oddName won" : "oddName lost"} style={won[0] === "WON" ? {} : {backgroundColor: appConfig.palette['main-color']}}>
                        {t(won[0])}
                    </span>
                )}
            </div>
            {isDummy && (
                <Link to="/subscribe" className="subscribeButton">
                    {t("Subscribe to see more")}
                </Link>
            )}
        </div>
    );
}

export default PickCard;
