import {useDispatch, useSelector} from "react-redux";
import {addFilters, removeFilters} from "../../store/filters/filtersSlice";
import findObjectKey from "../../helpers/findObjectKey";
import {useTranslation} from "react-i18next";

function FilterCheckbox({name, isChecked, entityTitle}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const leagues = useSelector(state => state.leagues);
    const actionToDispatch = isChecked ? removeFilters : addFilters;

    const handleChildClick = (e) => {
        e.stopPropagation();
    }

    const handleCheckboxChangeAction = (checkboxName) => {
        if (entityTitle === 'league') {
            const sportName = findObjectKey(leagues, checkboxName);
            dispatch(actionToDispatch({
                type: entityTitle,
                name: checkboxName,
                relatedSport: sportName,
                checked: !isChecked
            }));
        } else if (entityTitle === 'sport') {
            dispatch(actionToDispatch({
                type: entityTitle,
                name: checkboxName,
                relatedLeagues: leagues[checkboxName],
                checked: !isChecked,
            }));
        } else {
            dispatch(actionToDispatch({type: entityTitle, name: checkboxName, checked: !isChecked,}));
        }
    };

    return (
        <label className="checkboxContainer" onClick={handleChildClick}>
            {t(name)}
            <input
                type="checkbox"
                checked={isChecked}
                onChange={() => handleCheckboxChangeAction(name)}
            />
            <span className="checkmark"></span>
        </label>
    )
}

export default FilterCheckbox;
