import './index.css';
import {useTranslation} from "react-i18next";
import getConfigForDomain from "../../helpers/getConfigForDomain";

function SubscribeCard({price, title, isActive, showBestDeal, onClick, description, currency}) {
    const appConfig = getConfigForDomain(process.env.REACT_APP_DOMAIN);
    const { t } = useTranslation();
    return (
        <div className={isActive ? 'subscribeCard active' : 'subscribeCard'} onClick={onClick} style={isActive ? {backgroundColor: appConfig.palette['main-color'], borderColor: appConfig.palette['main-color']} : {}}>
            <div className="subscribeHeader">
                <span className="cardPrice">{price + currency}</span>
                <span className="cardTitle">{t(title)}</span>
            </div>
            <div className="subscribeText">
                {t(title + ' description')}
            </div>
            {showBestDeal && (
                <div className="bestDeal" style={{color: appConfig.palette['main-color']}}>
                    {t("Best deal")}
                </div>
            )}
        </div>
    );
}

export default SubscribeCard;
