import Modal from "../../components/Modal";
import {Link} from "react-router-dom";
import getConfigForDomain from "../../helpers/getConfigForDomain";
import {useTranslation} from "react-i18next";

function SuccessfulPayment() {
    const appConfig = getConfigForDomain(process.env.REACT_APP_DOMAIN);
    const { t } = useTranslation();

    return (
        <Modal>
            <span style={{marginBottom: '16px'}}>{t("Successful payment")}</span>
            <div className="disclaimer" style={{margin: '0 0 16px'}}>
                {t("Successful disclaimer")}
            </div>
            <Link to='/' className="submitButton" style={{backgroundColor: appConfig.palette['main-color']}}>{t('Continue')}</Link>
        </Modal>
    )
}

export default SuccessfulPayment;
