import FetchUser from "../Auth";
import UserProfileForm from "../../components/UserProfileForm";
import {useTranslation} from "react-i18next";
import Title from "../../components/Title";

function UserProfile() {
    const { t } = useTranslation();
    const user = FetchUser;

    return (
        <>
            <Title text="Profile" headingLevel="1" additionalStyles={{marginBottom: '16px'}}/>
            <UserProfileForm userAttributes={user.attributes} user={user}/>
        </>
    )
}

export default UserProfile;
