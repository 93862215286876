import {useEffect, useMemo, useRef, useState} from 'react';
import Chevron from './images/chevron-down.svg';
import "./index.css";
import generateCheckboxesData from "../../helpers/generateCheckboxesData";
import FilterCheckbox from "../FilterCheckbox";
import findObjectKey from "../../helpers/findObjectKey";
import {useDispatch, useSelector} from "react-redux";
import {addFilters} from "../../store/filters/filtersSlice";
import {useTranslation} from "react-i18next";

function DropdownList({ title, data }) {
    const { t } = useTranslation();
    const dropdownRef = useRef(null);
    const [showList, setShowList] = useState(false);
    const dispatch = useDispatch();
    const leagues = useSelector(state => state.leagues);
    const filters = useSelector(state => state.filters);
    const checkboxes = useMemo(() => generateCheckboxesData(filters, title), [filters, title]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                // Click occurred outside the dropdown
                setShowList(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (data) {
            if(Array.isArray(data)) {
                data.forEach(name => saveDataToStore(name));
            } else {
                for (const sportName in data) {
                    const sportLeagues = data[sportName];

                    sportLeagues.forEach(league => saveDataToStore(league));
                }
            }
        }
    }, [data]);

    const saveDataToStore = (checkboxName)  => {
        if (title === 'league') {
            const sportName = findObjectKey(leagues, checkboxName);
            dispatch(addFilters({
                type: title,
                name: checkboxName,
                relatedSport: sportName,
                checked: false
            }));
        } else if (title === 'sport') {
            dispatch(addFilters({
                type: title,
                name: checkboxName,
                relatedLeagues: leagues[checkboxName],
                checked: false
            }));
        } else {
            dispatch(addFilters({type: title, name: checkboxName, checked: false}));
        }
    }

    return (
        <>
            {checkboxes && checkboxes.length > 0 && (
                <div className="dropdownButton" ref={dropdownRef} onClick={() => setShowList(!showList)}>
                    {t(title)}
                    <img src={Chevron} alt="chevron"/>
                    {showList && (
                        <div className="dropdownList">
                            {checkboxes.map((item) => {
                                return (
                                    <FilterCheckbox name={item.name} isChecked={item.checked} entityTitle={title} key={item.name}/>
                                );
                            })}
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default DropdownList;
