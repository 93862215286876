import usePicks from "../../hooks/usePicks";
import PicksLayout from "../PicksLayout";
import {updatePicks} from "../../store/picks/picksSlice";
import {useTranslation} from "react-i18next";
import Loader from "../Loader";

function PicksActive({activeButton, user}) {
    const {t} = useTranslation();
    const [sportNames, picks, isLoading] = usePicks(activeButton, user, updatePicks, true);

    return (
        <>
            {isLoading ? (
                <Loader dimensions={{width: "20px", height: "20px"}}/>
            ) : (
                <>
                    {sportNames && sportNames.length > 0 ? (
                        sportNames.map((title, index) => (
                            <PicksLayout sportName={title} picks={picks[index]} user={user} key={index} isHistory={activeButton === 'Pick History'}/>
                        ))
                    ) : (
                        <h2>{t("No picks for today!")}</h2>
                    )}
                </>
            )}
        </>
    )
}

export default PicksActive;
