import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Title from "../../components/Title";
import getConfigForDomain from "../../helpers/getConfigForDomain";
import {useTranslation} from "react-i18next";

function AboutUs() {
    const {pathname} = useLocation();
    const appConfig = getConfigForDomain(process.env.REACT_APP_DOMAIN);
    const locale = appConfig.localization;
    const parse = require('html-react-parser');
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const element = document.documentElement;
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [pathname]);

    const aboutUsText = {
        sv: {
            section1: "<p>Välkommen till PickSensei, vår nya speltipsservice som först lanserades på TVMatchen.nu! Via tjänsten kommer du att få dagliga speltips från erfarna tippare, direkt i din mailkorg och på picks.tvmatchen.nu. Tveka inte att prova vår expertis genom att använda våra dagliga gratistips eller att prenumerera för mer!</p>",
            section2: "<h2 class='titleName'>OM TJÄNSTEN</h2><p>Vårt mål är att leverera vinstgivande speltips till matcher från de största ligorna i de mest populära sporterna, med odds som finns tillgängliga hos de flesta spelbolag. Vi kommer att fokusera på huvudmarknaderna i toppligorna (fulltid och över/underspel). De speltips som våra experter presenterar är alltid sådana som finns universellt tillgängliga vid tillfället för publiceringen. Vi är experter på att hinna före marknaden, så se till att du har placerat dina spel så snabbt som möjligt. Detta då marknaden rör sig mot vår position 75% av gångerna, vilket innebär att du kommer att få allt sämre odds desto senare du placerar ditt spel.</p><p>Vi tror mycket på så kallad ”closing line value” och vår långsiktiga framgång utgår från att slå marknaden inom alla sporter. Marknaderna som vi spelar på är väldigt effektiva, så att slå dem genom att få bättre odds än marknaden gör det möjligt får oss och våra prenumeranter att tjäna på våra speltips i det långa loppet.</p>",
            section3: "<h2 class='titleName'>VILKA SPORTER FOKUSERAR VI PÅ</h2><p>I nuläget fokuserar vi först och främst på amerikanska sporter – särskilt NBA, MLB och NFL – men vi kommer också att ha speltips på amerikansk fotboll, collegebasket och kampsporter. Vi siktar på att addera ytterligare rekare till vårt skickliga team så att vi kan vidga spannet för vad tjänsten har att erbjuda.</p>",
            section4: "<h2 class='titleName'>BET SIZING</h2> <p>Vi använder standardiserad värdering av våra bets, med 1 units per spel. Vi föreslår att du riskerar högst 2% av din bankrulle på varje enskilt spel. Idealt vore det bäst om du siktade på att riskera högst 1% av bankrulle på varje spel.</p>",
            section5: "<h2 class='titleName'>NÄR LADDAS TIPSEN UPP</h2><p>Generellt kommer tipsen att laddas upp tidigt på morgonen samma dag som matchen spelas. Limits ska vara höga på speldagarna och spelmarknaderna ska vara brett tillgängliga hos flera olika spelbolag. Vi betygsätter resultaten av föregående dags speltips varje arbetsdag (måndag–fredag).</p>",
            section6: "<h2 class='titleName'>LEVERANS AV TIPSEN</h2><p>Tipsen levereras via mailnotiser till din registrerade mailadress. Vi kommer att skicka 1-3 mail dagligen, beroende hur många speltips vi laddar upp.</p>",
            section7: "<h2 class='titleName'>KONTAKTA OSS</h2><p>Tveka inte att höra av dig till oss på <a href='mailto:info@picksensei.com' style='color:#0092d0;'>info@picksensei.com</a> om din fråga inte har besvarats i det här dokumentet eller på picks.tvmatchen.nu.</p>"
        }
    }

    return (
        <div className="termsBox">
            <Title text="About us" headingLevel="1"/>
            {parse(aboutUsText[locale].section1)}
            {parse(aboutUsText[locale].section2)}
            {parse(aboutUsText[locale].section3)}
            {parse(aboutUsText[locale].section4)}
            {parse(aboutUsText[locale].section5)}
            {parse(aboutUsText[locale].section6)}
            {parse(aboutUsText[locale].section7)}
            <button className="submitButton" onClick={() => navigate(-1)}
                    style={{
                        backgroundColor: appConfig.palette['main-color'],
                        margin: "16px auto"
                    }}>{t('Go Back')}</button>
        </div>
    );
}

export default AboutUs;
