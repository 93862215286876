import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from './locales/en.json';
import svTranslation from './locales/sv.json';
import getConfigForDomain from "./helpers/getConfigForDomain";
const appConfig = getConfigForDomain(process.env.REACT_APP_DOMAIN);

const defaultLang = appConfig.localization || "en";
const debugMode = process.env.REACT_APP_TRANSLATIONS_DEBUG || false;

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: enTranslation },
            sv: { translation: svTranslation },
        },
        lng: defaultLang, // Default language
        fallbackLng: "en",
        debug: debugMode, // Enable debug mode for development
    });

export default i18n;
