function formatUserDataForBraze(userArr) {
    let result = [];

    if (userArr && userArr.length) {
        // Loop through the array of objects
        for (const obj of userArr) {
            let userObj = {
                external_user_id: '',
                email: '',
                email_subscribe: 'subscribed',
                attributes: {},
                send_to_existing_only: false,
                trigger_properties : {
                    date: getCurrentFormattedDate(),
                }
            };
            if (obj.Attributes && Array.isArray(obj.Attributes)) {
                // Loop through the "Attributes" array
                for (const attr of obj.Attributes) {
                    switch (attr.Name) {
                        case 'sub':
                            userObj.external_user_id = attr.Value;
                            userObj.attributes.external_user_id = attr.Value;
                            break;
                        case 'email':
                            userObj.email = attr.Value;
                            userObj.attributes.email = attr.Value;
                            break;
                        case 'nickname':
                            userObj.attributes.first_name = attr.Value;
                        default:
                            break;
                    }
                    // You can add more conditions to extract other attributes if needed
                }
                result.push(userObj);
            }
        }
    }

    return result;
}

export default formatUserDataForBraze;

function getCurrentFormattedDate() {
    const date = new Date();

    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const dayOfWeek = daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];

    function getDayWithSuffix(day) {
        if (day >= 11 && day <= 13) {
            return day + "th";
        }
        const lastDigit = day % 10;
        switch (lastDigit) {
            case 1:
                return day + "st";
            case 2:
                return day + "nd";
            case 3:
                return day + "rd";
            default:
                return day + "th";
        }
    }

    const dayWithSuffix = getDayWithSuffix(dayOfMonth);

    return `${dayOfWeek} ${dayWithSuffix} ${month}`;
}
