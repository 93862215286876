import {API} from "aws-amplify";

async function createSubscriptionPortalSession(user, localization) {
    const clientID = user?.attributes?.sub;
    const stripeCustomerID = user?.attributes['custom:stripeCustomerID'];

    const requestData = {
        headers: {"Content-Type": "application/json"},
        body: {"clientId": clientID, "stripeCustomerID": stripeCustomerID, "locale": localization}
    }

    if (stripeCustomerID && stripeCustomerID !== '-') {
        await API.post('picksenseiAPI', '/subscription-portal-session', requestData).then(({url}) => {
            // On success redirect the customer to the returned URL
            window.location = url
        }).catch(e => {
            console.log(e);
        });
    } else {
        console.log('Stripe customer ID is empty');
    }
}

export default createSubscriptionPortalSession;
