import {useState, useEffect} from 'react';
import './index.css';
import {Auth} from "aws-amplify";
import {useTranslation} from "react-i18next";
import getConfigForDomain from "../../helpers/getConfigForDomain";
import createSubscriptionPortalSession from "../../helpers/createSubscriptionPortalSession";
import Loader from "../Loader";
import Title from "../Title";

function UserProfileForm({userAttributes, user}) {
    const appConfig = getConfigForDomain(process.env.REACT_APP_DOMAIN);
    const {t} = useTranslation();
    const [editedAttributes, setEditedAttributes] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [showSaveLoader, setShowSaveLoader] = useState(false);

    useEffect(() => {
        // Initialize editedAttributes with current user attributes
        setEditedAttributes(userAttributes);
    }, [userAttributes]);

    const handleChange = (attribute, value) => {
        // Update the editedAttributes state when an input changes
        setEditedAttributes(prevEditedAttributes => ({
            ...prevEditedAttributes,
            [attribute]: value
        }));
    };

    const handleSave = async () => {
        setShowSaveLoader(true);
        try {
            // Check if there are changes before saving
            if (!isAttributesEqual(editedAttributes, userAttributes)) {
                await Auth.updateUserAttributes(user, editedAttributes);
                setShowSaveLoader(false);
            } else {
                setShowSaveLoader(false);
                console.log('No changes to save.');
            }
        } catch (error) {
            setShowSaveLoader(false);
            console.error('Error saving records:', error);
        }
    };

    const handleSubscriptionClick = async (user, localization) => {
        setShowLoader(true); // Set showLoader to true before the operation begins
        try {
            // Perform the operation that requires some time (e.g., API call)
            await createSubscriptionPortalSession(user, localization);
            setShowLoader(false);
        } catch (error) {
            setShowLoader(false);
        }
    };

    const isAttributesEqual = (attr1, attr2) => {
        return JSON.stringify(attr1) === JSON.stringify(attr2);
    };

    return (
        <div>
            <Title text="Main Information" headingLevel="2"/>
            <div className="formDataBox">
                <label htmlFor="email">{t("Email")}:</label>
                <input
                    type="text"
                    id="email"
                    value={editedAttributes.email || ''}
                    onChange={(e) => handleChange('email', e.target.value)}
                />
            </div>
            {editedAttributes['address'] && userAttributes['custom:membership'] === 'premium' ? (
                <div className="formDataBox">
                    <label htmlFor="address">{t("Address")}: </label>
                    <input
                        type="text"
                        id="address"
                        value={editedAttributes['address'] || ''}
                        onChange={(e) => handleChange('address', e.target.value)}
                    />
                </div>
            ) : null}
            <div className="formDataBox">
                <label>{t("Membership")}:</label>
                <input
                    type="text"
                    value={t(editedAttributes['custom:membership']) || ''}
                    onChange={(e) => handleChange('membership', e.target.value)}
                    disabled="disabled"
                />
            </div>
            {user && userAttributes['custom:membership'] === 'premium' && (
                <div className="formDataBox">
                    <label>{t("Manage subscription")}:</label>
                    <button className={showLoader ? "submitButton submitButtonFlex" : "submitButton"}
                            onClick={() => handleSubscriptionClick(user, appConfig.localization)}
                            style={{backgroundColor: appConfig.palette['main-color']}}
                            disabled={showLoader}>
                        {showLoader ? <Loader dimensions={{width: "20px", height: "20px"}}/> : t("Manage")}
                    </button>
                </div>
            )}
            <button onClick={handleSave} className={showSaveLoader ? "submitButton submitButtonFlex" : "submitButton"}
                    style={{backgroundColor: appConfig.palette['main-color']}}
                    disabled={showSaveLoader || isAttributesEqual(editedAttributes, userAttributes)}>
                {showSaveLoader ? <Loader dimensions={{width: "20px", height: "20px"}}/> : t("Save Changes")}
            </button>
        </div>
    );
}

export default UserProfileForm;
