import {DataStore} from "@aws-amplify/datastore";
import {Pick} from "../models";
import formatStatisticData from "../helpers/formatStatisticData";

async function getExpertDataFunction(personaName) {
    const picks = await DataStore.query(Pick, (p) => p.and(p => [
        p.Persona.name.eq(personaName),
        p.or(p => [p.won.contains('WON'), p.won.contains('LOST'), p.won.contains('VOID')])
    ]));

    if (picks.length > 0) {
        const persona = await picks[0]?.Persona;
        const fileURL = persona && persona.image ? 'https://' + process.env.REACT_APP_STORAGE_ID + '.s3.amazonaws.com/public' + persona.image : '';
        const formattedData = await formatStatisticData(picks);

        return [...formattedData, fileURL];
    }
}

export default getExpertDataFunction;
