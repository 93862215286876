function groupBySport(arrayOfPicks) {
    return arrayOfPicks.reduce((result, currentItem) => {
        const sportName = currentItem.sport.name;

        if (!result[sportName]) {
            result[sportName] = [];
        }

        result[sportName].push(currentItem);

        return result;
    }, {});
}

export default groupBySport;
