import {Link, Navigate} from 'react-router-dom';
import FetchUser from "../../views/Auth";
import Modal from "../Modal";
import {useTranslation} from "react-i18next";
import getConfigForDomain from "../../helpers/getConfigForDomain";

const PremiumRestrictRoute = ({ children }) => {
    const user = FetchUser;
    const isPremium = user?.attributes['custom:membership'] === 'premium';
    const {t} = useTranslation();
    const appConfig = getConfigForDomain(process.env.REACT_APP_DOMAIN);

    return isPremium ?
        <Modal>
            <span style={{marginBottom: '16px'}}>{t("Manage subscription modal")}</span>
            <Link to={'/user/' + user.username} className="submitButton" style={{backgroundColor: appConfig.palette['main-color']}}>{t("Visit")}</Link>
        </Modal>
        : children;
}

export default PremiumRestrictRoute;
