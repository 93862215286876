import { Hub } from "aws-amplify";
import { DataStore } from "@aws-amplify/datastore";

let isDatastoreReady = false;

const checkDatastoreReady = async () => {
    try {
        await DataStore.start();

        const listener = Hub.listen("datastore", async (hubData) => {
            const { event } = hubData.payload;
            if (event === "ready") {
                isDatastoreReady = true;
                listener(); // Stop listening once DataStore is ready
            }
        });

        return new Promise((resolve) => {
            const interval = setInterval(() => {
                if (isDatastoreReady) {
                    clearInterval(interval);
                    resolve({ isDatastoreReady, stopListening: () => listener() });
                }
            }, 100);
        });
    } catch (error) {
        throw new Error("Error initializing DataStore: " + error);
    }
};

export default checkDatastoreReady;
