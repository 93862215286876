import {DataStore} from "@aws-amplify/datastore";
import {Pick} from "../../models";
import {useEffect, useState} from "react";
import formatStatisticData from "../../helpers/formatStatisticData";

const useAppStatistic = () => {
    const [appStatistic, setAppStatistic] = useState([]);

    useEffect(() => {
        getAppStatistic();
    }, []);

    async function getAppStatistic() {
        try {
            const picks = await DataStore.query(Pick, (p) => p.or(p => [p.won.contains('WON'), p.won.contains('LOST'), p.won.contains('VOID')]));

            if (picks.length) {
                const data = await formatStatisticData(picks);
                setAppStatistic(data);
            }
        } catch (error) {
            console.error('Error querying records:', error);
        }
    }

    return appStatistic;
}

export default useAppStatistic;
