import AppConfig from '../appConfig.json';
import {logos} from './logos';

function getConfigForDomain(domain) {
    const domainConfig = AppConfig[domain] || {};
    const defaultConfig = AppConfig.default;

    return {
        title: domainConfig.title || defaultConfig.title,
        description: domainConfig.description || defaultConfig.description,
        logo: getLogoPath(domainConfig.logo) || getLogoPath(defaultConfig.logo),
        favicon: domainConfig.favicon || defaultConfig.favicon,
        localization: domainConfig.localization || defaultConfig.localization,
        palette: domainConfig.palette || defaultConfig.palette,
        GATag: domainConfig.GATag || defaultConfig.GATag,
        headerStyle: domainConfig.headerStyle || defaultConfig.headerStyle,
        isFooterActive: domainConfig.isFooterActive || defaultConfig.isFooterActive,
        footerContent: domainConfig.footerContent || defaultConfig.footerContent
    };
}

export default getConfigForDomain;

function getLogoPath(logoName) {
    const logo = logos.find(el => el.text === logoName);
    return logo ? logo.imageSrc : '';
}
