import eye from '../../public/images/eye.png';
import eyeHidden from '../../public/images/eye-hidden.png';

const PasswordToggle = ({ type, setType }) => {
    const icon = type === 'password' ? eyeHidden : eye;

    const handleToggle = () => {
        setType((prevType) => (prevType === 'password' ? 'text' : 'password'));
    };

    return (
        <span className="showPass" onClick={handleToggle}>
            <img src={icon} alt="eye" />
        </span>
    );
};

export default PasswordToggle;
