import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import getConfigForDomain from '../../helpers/getConfigForDomain';
import createStripeCheckoutSession from '../../helpers/createStripeCheckoutSession';
import Loader from '../../components/Loader';
import PasswordToggle from "../../components/PasswordToggle";

function SigninForm() {
    const appConfig = getConfigForDomain(process.env.REACT_APP_DOMAIN);
    const { t } = useTranslation();
    const [username, setUsername] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [password, setPassword] = useState('');
    const [type, setType] = useState('password');

    const [searchParams] = useSearchParams();
    const stripeID = searchParams.get('stripeID') || null;

    const onChangeUsernameHandler = (event) => {
        setUsername(event.target.value);
        setShowErrorMessage(false);
    };

    const onChangePasswordHandler = (event) => {
        setPassword(event.target.value);
        setShowErrorMessage(false);
    };

    const signIn = async (event) => {
        event.preventDefault(); // Prevents default form submission behavior

        try {
            setShowLoader(true);
            const user = await Auth.signIn(username, password);

            if (user && user.attributes['custom:membership'] !== 'premium' && stripeID) {
                await createStripeCheckoutSession(stripeID, user, appConfig.localization);
            } else {
                window.location.replace('/');
            }
        } catch (error) {
            setShowErrorMessage(true);
            setErrorMsg(error.message);
            setShowLoader(false);
        }
    };

    return (
        <form onSubmit={signIn}> {/* Wrap form around input fields */}
            <div className="formBox">
                {showErrorMessage && errorMsg ? (
                    <p className="message login_error">
                        <strong>{t('Error')}: </strong>
                        {errorMsg}
                    </p>
                ) : null}
                <div className="form">
                    <div className="formInputBox">
                        <label htmlFor="username" className="formLabel">
                            {t('Email')}
                        </label>
                        <input
                            type="text"
                            id="username"
                            className="formInput"
                            value={username}
                            required
                            onChange={onChangeUsernameHandler}
                        />
                    </div>
                    <div className="formInputBox">
                        <label htmlFor="password" className="formLabel">
                            {t('Password')}
                        </label>
                        <input
                            type={type}
                            id="password"
                            value={password}
                            className="formInput"
                            required
                            onChange={onChangePasswordHandler}
                        />
                        <PasswordToggle type={type} setType={setType} />
                    </div>
                    <div>
                        <button
                            type="submit"
                            className={showLoader ? 'submitButton w-100 submitButtonFlex' : 'submitButton w-100'}
                            style={{ backgroundColor: appConfig.palette['main-color'] }}
                            disabled={showLoader}
                        >
                            {showLoader ? <Loader dimensions={{ width: '20px', height: '20px' }} /> : t('Log in')}
                        </button>
                    </div>
                </div>
                <div className="formBackLink">
                    <Link to="/register" style={{ color: appConfig.palette['main-color'] }}>
                        {t('Register')}
                    </Link>
                    <span> | </span>
                    <Link to="/reset" style={{ color: appConfig.palette['main-color'] }}>
                        {t('Lost your password?')}
                    </Link>
                </div>
            </div>
        </form>
    );
}

export default SigninForm;
