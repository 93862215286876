import './index.css';
import {sportsIcons} from "../../helpers/sportsIcons";
import {useTranslation} from "react-i18next";

function Title({text, showIcon, additionalStyles, headingLevel}) {
    const { t } = useTranslation();
    const Tag = 'h' + headingLevel;
    const getSportIconSrc = name =>
        sportsIcons.filter(el => el.text === name).map(o => o.imageSrc);

    return (
        <div className={showIcon ? "titleBox icon" : "titleBox"} style={additionalStyles ? additionalStyles : {}}>
            {showIcon && getSportIconSrc(text).length ? (
                <img src={getSportIconSrc(text)[0]} alt={text + ' icon'} className="sportIcon"/>
            ) : null}
            <Tag className="titleName">{t(text)}</Tag>
        </div>
    )
}

export default Title;
