const filterData = (data, filters) => {
    return data.filter(item => {
        const sportFilterNames = filters.sports.filter(sport => sport.checked).map(sport => sport.name);
        const leagueFilterNames = filters.leagues.filter(league => league.checked).map(league => league.name);
        const expertFilterNames = filters.experts.filter(expert => expert.checked).map(expert => expert.name);

        const sportCondition = sportFilterNames.length === 0 || sportFilterNames.includes(item.sport.name);
        const leagueCondition = leagueFilterNames.length === 0 || leagueFilterNames.includes(item.league.name);
        const expertCondition = expertFilterNames.length === 0 || expertFilterNames.includes(item.persona.name);

        return sportCondition && leagueCondition && expertCondition;
    });
};

export default filterData;
