import {useEffect, useState} from "react";
import {DataStore} from "@aws-amplify/datastore";
import {Pick} from "../../models";
import {useDispatch, useSelector} from "react-redux";
import isObjectEmpty from "../../helpers/isObjectEmpty";
import {resetFilters} from "../../store/filters/filtersSlice";
import groupBySport from "../../helpers/groupPicksBySport";
import fetchModels from "../../helpers/fetchRelatedModels";
import {SortDirection} from "aws-amplify";

const usePicks = (activeButton, user, reducer, reset) => {
    const dispatch = useDispatch();
    const picks = useSelector((state) => state.picks);
    const picksHistory = useSelector((state) => state.picksHistory);
    const [isLoading, setLoading] = useState(true);
    const {isDatastoreReady} = useSelector((state) => state.dataStore);

    useEffect(() => {
        if (isDatastoreReady) {
            getPicks(activeButton);
        }
    }, [activeButton, isDatastoreReady]);

    async function getPicks(button) {
        const startOfDay = new Date();
        startOfDay.setUTCHours(0, 0, 0, 0);

        let picksQuery;
        let options = {limit: 20};

        if (button === 'Active picks') {
            picksQuery = (p) => p.and(p =>
                [p.eventDate.gt(startOfDay.toISOString())]
            );
        } else {
            picksQuery = (p) => p.and(p =>
                [p.eventDate.lt(startOfDay.toISOString()), p.or(p => [p.won.contains('WON'), p.won.contains('LOST'), p.won.contains('VOID')])]
            );
            options.sort = (s) => s.eventDate(SortDirection.DESCENDING);
        }

        try {
            const picks = await DataStore.query(Pick, picksQuery, options);

            if (picks.length) {
                const resArr = await Promise.all(picks.map(fetchModels));
                const picksGroupedBySport = groupBySport(resArr);
                if (reset) {
                    dispatch(resetFilters());
                }
                dispatch(reducer(picksGroupedBySport));
            } else {
                if (reset) {
                    dispatch(resetFilters());
                }
                dispatch(reducer({}));
            }

        } catch (error) {
            console.error('Error querying records:', error);
        } finally {
            setLoading(false); // Set loading state to false after fetching
        }
    }

    if (activeButton === 'history') {
        return [Object.keys(picksHistory), Object.values(picksHistory), isLoading];
    } else {
        if (isObjectEmpty(picks)) {
            return [[], [], isLoading];
        } else {
            return [Object.keys(picks), Object.values(picks), isLoading];
        }
    }
}

export default usePicks;
