import './index.css';
import {Link, useLocation, useParams} from "react-router-dom";
import Title from "../../components/Title";
import ExpertTable from "../../components/ExpertTable";
import capitalizeFirst from "../../helpers/capitalizeFirst";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import getConfigForDomain from "../../helpers/getConfigForDomain";

function ExpertProfile() {
    const {t} = useTranslation();
    const {name} = useParams();
    const {pathname} = useLocation();
    const appConfig = getConfigForDomain(process.env.REACT_APP_DOMAIN);

    useEffect(() => {
        const element = document.documentElement;
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [pathname]);

    return (
        <>
            <Title text={capitalizeFirst(name) + " " + t("Profile")} headingLevel="1"/>
            <ExpertTable name={capitalizeFirst(name)}/>
            <Link to="/experts" style={{
                color: appConfig.palette['main-color'],
                margin: "16px auto",
                display: 'block',
                width: 'fit-content'
            }}>{t('Check full app statistic')}</Link>
        </>
    );
}

export default ExpertProfile;
