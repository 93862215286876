import TVMatchen from '../public/images/logos/tvmatchen.png';
import Default from '../public/images/logos/default.png';

const logos = [
    {
        text: 'tvmatchen',
        imageSrc: TVMatchen,
    },
    {
        text: 'default',
        imageSrc: Default
    }
];

export { logos };
