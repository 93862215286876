// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const PickStatus = {
  "WON": "WON",
  "LOST": "LOST",
  "VOID": "VOID"
};

const { SubscribePlan, League, Sport, Persona, Pick } = initSchema(schema);

export {
  SubscribePlan,
  League,
  Sport,
  Persona,
  Pick,
  PickStatus
};