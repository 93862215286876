import { Auth } from 'aws-amplify';
import FetchUser from "../Auth";

function Logout() {

    const user = FetchUser;
    if( typeof user !== 'undefined' ) {
      signOut()
    }

    return (
        <>
        </>
    )
}

async function signOut() {
    try {
      await Auth.signOut();
      window.location.replace('/login');
    } catch (error) {
      console.log('error signing out: ', error);
    }
}

export default Logout
