import { Link } from 'react-router-dom';

const linkParser = {
    replace: (node) => {
        if (node.name === 'a') {
            const href = node.attribs.href || '/';
            const styleString = node.attribs.style || '';
            const target = node.attribs.target || '_self'; // Default target is '_self'
            const styleObj = styleString.split(';').reduce((acc, style) => {
                const [property, value] = style.split(':');
                if (property.trim() && value.trim()) {
                    acc[property.trim()] = value.trim();
                }
                return acc;
            }, {});

            return (
                <Link to={href} style={styleObj} target={target}>
                    {node.children[0]?.data}
                </Link>
            );
        }
    },
};

export default linkParser;
