import './index.css';
import SubscribeCard from "../../components/SubscribeCard";
import {useEffect, useState} from "react";
import Modal from "../../components/Modal";
import FetchUser from "../Auth";
import useSubscriptionPlans from "../../hooks/useSubscriptionPlans";
import createStripeCheckoutSession from "../../helpers/createStripeCheckoutSession";
import {useTranslation} from "react-i18next";
import getConfigForDomain from "../../helpers/getConfigForDomain";
import Loader from "../../components/Loader";
import {Link} from "react-router-dom";
import linkParser from "../../helpers/linkParser";

function Subscribe() {
    const appConfig = getConfigForDomain(process.env.REACT_APP_DOMAIN);
    const {t} = useTranslation();
    const parse = require('html-react-parser');
    const user = FetchUser;
    const {initialStripeID, activeButtonTitle, plans, isLoading} = useSubscriptionPlans();
    const [activeButton, setActiveButton] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [activeStripeID, setActiveStripeID] = useState("");
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if (initialStripeID) {
            setActiveStripeID(initialStripeID);
        }

        if (plans && plans.length > 0) {
            setSubscriptionPlans(plans);
            setActiveButton(activeButtonTitle);
        }
    }, [initialStripeID, plans, activeButtonTitle]);

    const handleButtonClick = (buttonId, stripePriceId) => {
        setActiveButton(buttonId);
        setActiveStripeID(stripePriceId);
    };

    const handleStripeCheckoutClick = async (activeStripeID, user, appConfig) => {
        setShowLoader(true); // Set showLoader to true before the operation begins
        try {
            // Perform the operation that requires some time (e.g., API call)
            await createStripeCheckoutSession(activeStripeID, user, appConfig.localization);
        } catch (error) {
            setShowLoader(false);
        }
    };

    return (
        <>
            <h1>{parse(t('Subscribe Title'))}</h1>
            <span className="aboutUsLink">{parse(t('To learn more about our service:'), {
                replace: (node) => linkParser.replace(node),
            })}
            </span>
            {isLoading ? (
                <Loader dimensions={{width: "20px", height: "20px"}}
                        additionalStyles={{display: 'block', margin: '0 auto'}}/>
            ) : (
                <>
                    {subscriptionPlans && subscriptionPlans.map((plan) => (
                        <SubscribeCard
                            key={plan.id}
                            title={plan.title}
                            price={plan.price}
                            isActive={activeButton === plan.title}
                            showBestDeal={plan.isBestDeal}
                            description={plan.description}
                            currency={plan.currency}
                            onClick={() => handleButtonClick(plan.title, plan.stripePriceID)}
                        />
                    ))}
                </>
            )}
            <div className="disclaimer">
                {t('Subscribe disclaimer')}
            </div>
            {user ? (
                <button className={showLoader ? "subscribeButton page subscribeButtonFlex" : "subscribeButton page"}
                        onClick={() => handleStripeCheckoutClick(activeStripeID, user, appConfig)}
                        style={{backgroundColor: appConfig.palette['main-color']}}
                        disabled={showLoader}>
                    {showLoader ? <Loader dimensions={{width: "19px", height: "19px"}}/> : t("Checkout")}
                </button>
            ) : (
                <>
                    <button className="subscribeButton page"
                            style={{backgroundColor: appConfig.palette['main-color']}}
                            onClick={() => setModalIsOpen(true)}>{t("Subscribe")}</button>
                    {modalIsOpen && (
                        <Modal setModalIsOpen={setModalIsOpen}>
                            <span>{t("Subscribe modal")}</span>
                            <div className="modalLinks">
                                <Link to={"/login/" + '?stripeID=' + activeStripeID} className="submitButton"
                                      style={{backgroundColor: appConfig.palette['main-color']}}>{t("Log in")}</Link>
                                <Link to={"/register/" + '?stripeID=' + activeStripeID} className="submitButton"
                                      style={{backgroundColor: appConfig.palette['main-color']}}>{t("Register")}</Link>
                            </div>
                        </Modal>
                    )}
                </>
            )}
        </>
    );
}

export default Subscribe;
