import { useEffect, useState } from "react";
import { DataStore } from "@aws-amplify/datastore";
import { Persona } from "../../models";
import getExpertDataFunction from "../../helpers/getExpertDataFunction";

const useExperts = () => {
    const [experts, setExperts] = useState([]);

    useEffect(() => {
        getExperts();
    }, []);

    async function getExperts() {
        try {
            const expertsInDB = await DataStore.query(Persona);

            if (expertsInDB.length) {
                const arrayOfExpertNames = [];

                for (const expertObj of expertsInDB) {
                    const data = await getExpertDataFunction(expertObj.name);

                    if (data && data.length) {
                        const oddsSum = data[1].oddsSum;
                        // Add the expert name and oddsSum to the array
                        arrayOfExpertNames.push({ name: expertObj.name, oddsSum });
                    }
                }

                // Sort the array in descending order by oddsSum
                arrayOfExpertNames.sort((a, b) => b.oddsSum - a.oddsSum);

                // Extract only the sorted expert names
                const sortedExpertNames = arrayOfExpertNames.map((obj) => obj.name);

                setExperts(sortedExpertNames);
            }
        } catch (error) {
            console.error("Error querying records:", error);
        }
    }

    return experts;
};

export default useExperts;
