import useExperts from "../../hooks/useExperts";
import Title from "../../components/Title";
import ExpertTable from "../../components/ExpertTable";
import useAppStatistic from "../../hooks/useAppStatistic";
import StatisticTable from "../../components/StatisticTable";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import getConfigForDomain from "../../helpers/getConfigForDomain";
import {useTranslation} from "react-i18next";

function Experts() {
    const experts = useExperts();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [statisticsBySport, overallStatistics] = useAppStatistic();
    const {pathname} = useLocation();
    const appConfig = getConfigForDomain(process.env.REACT_APP_DOMAIN);

    useEffect(() => {
        const element = document.documentElement;
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [pathname]);


    return (
        <>
            <Title text="Experts records" headingLevel="1"/>
            {statisticsBySport && overallStatistics && (
                <StatisticTable name="Picksensei" overallStatistics={overallStatistics}
                                statisticsBySport={statisticsBySport}/>
            )}
            {experts.map((expertName) => (
                <ExpertTable key={expertName} name={expertName}/>
            ))}
            <button className="submitButton" onClick={() => navigate(-1)}
                    style={{backgroundColor: appConfig.palette['main-color'], margin: "16px auto"}}>{t('Go Back')}</button>
        </>
    )
}

export default Experts;
