function generateCheckboxesData(filters, title) {
    const filteringTitle = title + 's';

    const filtersCheckbox = filters[filteringTitle] || [];

    if (filtersCheckbox.length) {
        return filtersCheckbox;
    }
}


export default generateCheckboxesData;

