import capitalizeFirst from "../../helpers/capitalizeFirst";
import {useTranslation} from "react-i18next";

function StatisticTable({name, statisticsBySport, overallStatistics, imageURL = ""}) {
    const { t } = useTranslation();
    const formatRecord = (record) => {
        return `${record?.winCount}-${record?.loseCount}-${record?.voidCount}`;
    };

    const formatOddsSum = (oddsSum) => {
        return oddsSum > 0 ? `+${oddsSum?.toFixed(2)}` : oddsSum?.toFixed(2);
    };

    return (
        <>
            {statisticsBySport && overallStatistics && (
                <div className="expertTable">
                    <div className="expertTableHeader">
                        {imageURL && (
                            <img src={imageURL} alt={capitalizeFirst(name) + "'s image"} className="expertTableExpertImage"/>
                        )}
                        {capitalizeFirst(name)}'s {name === 'Picksensei' ? t('combined')  : ''} {t("Record")}
                    </div>
                    <div className="expertTableRow">
                        <span className="subheaderTitle">Sport</span>
                        <span className="subheaderTitle">{t("Record")} W-P-P</span>
                        <span className="subheaderTitle">{t("Units")}</span>
                    </div>
                    {Object.keys(statisticsBySport).map(sport => (
                        <div className="expertTableRow" key={sport}>
                            <span className="dataTitle">{t(sport)}</span>
                            <span className="dataTitle">
                            {formatRecord(statisticsBySport[sport])}
                        </span>
                            <span className="dataTitle">
                            {formatOddsSum(statisticsBySport[sport].oddsSum)}
                        </span>
                        </div>
                    ))}
                    <div className="expertTableFooter">
                        <span className="footerTitle">{t("Overall")}</span>
                        <span className="footerTitle">
                        {formatRecord(overallStatistics)}
                    </span>
                        <span className="footerTitle">
                        {formatOddsSum(overallStatistics?.oddsSum)}
                    </span>
                    </div>
                </div>
            )}
        </>
    )
}
export default StatisticTable;
