import { useEffect, useState } from "react";
import { DataStore } from "@aws-amplify/datastore";
import { SubscribePlan } from "../../models";
import { Predicates, SortDirection } from "aws-amplify";
import {useSelector} from "react-redux";

const useSubscriptionPlans = () => {
    const [plans, setPlans] = useState([]);
    const [initialStripeID, setInitialStripeId] = useState("");
    const [activeButtonTitle, setActiveButtonTitle] = useState("");
    const {isDatastoreReady} = useSelector((state) => state.dataStore);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if (isDatastoreReady) {
            getPlans();
        }
    }, [isDatastoreReady]);

    async function getPlans() {
        try {
            const plans = await DataStore.query(SubscribePlan, Predicates.ALL, {
                sort: (s) => s.price(SortDirection.ASCENDING),
            });

            if (plans.length > 0) {
                const bestDealPlan = plans.find((item) => item.isBestDeal === true);

                if (bestDealPlan) {
                    const { stripePriceID, title } = bestDealPlan;
                    setPlans(plans);
                    setInitialStripeId(stripePriceID);
                    setActiveButtonTitle(title);
                }
            }
        } catch (error) {
            console.error("Error querying records:", error);
        } finally {
            setLoading(false);
        }
    }

    return plans.length
        ? { initialStripeID, activeButtonTitle, plans, isLoading }
        : { initialStripeID: '', activeButtonTitle: '', plans: [], isLoading};
};

export default useSubscriptionPlans;
