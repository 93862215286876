import { Navigate } from 'react-router-dom';
import FetchUser from "../../views/Auth";

const PrivateRoute = ({ children }) => {
    const user = FetchUser;

    return user ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
