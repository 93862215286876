import Football from '../public/images/sportsIcons/Icon_Fotboll.svg';
import Basketball from '../public/images/sportsIcons/Icon_Basketball.svg';
import Hockey from '../public/images/sportsIcons/Icon_IceHokey.png';
import AmFootball from '../public/images/sportsIcons/Icon_AmFootball.png';
import Baseball from '../public/images/sportsIcons/Icon_Baseball.png';
import MMA from '../public/images/sportsIcons/Icon_MMA.png';

const sportsIcons = [
    {
        text: 'Football',
        imageSrc: Football,
    },
    {
        text: 'Basketball',
        imageSrc: Basketball
    },
    {
        text: 'Ice Hockey',
        imageSrc: Hockey
    },
    {
        text: 'American Football',
        imageSrc: AmFootball
    },
    {
        text: 'Baseball',
        imageSrc: Baseball
    },
    {
        text: 'MMA',
        imageSrc: MMA
    },
];

export { sportsIcons };
