import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import getExpertDataFunction from "../../helpers/getExpertDataFunction";

const useExpertData = (personaName) => {
    const [expertData, setExpertData] = useState([]);
    const {isDatastoreReady} = useSelector((state) => state.dataStore);

    useEffect(() => {
        if (isDatastoreReady) {
            getExpertData();
        }
    }, [personaName, isDatastoreReady]);

    async function getExpertData() {
        try {
            const data = await getExpertDataFunction(personaName);
            data && data.length && setExpertData(data);
        } catch (error) {
            console.error('Error querying records:', error);
        }
    }

    return expertData;
}

export default useExpertData;
