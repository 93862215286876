import {useState} from 'react';
import '../../public/styles/forms.css';
import {Link} from 'react-router-dom';
import {Auth} from 'aws-amplify';
import {useTranslation} from 'react-i18next';
import getConfigForDomain from '../../helpers/getConfigForDomain';
import Loader from '../../components/Loader';
import './index.css';
import PasswordToggle from "../../components/PasswordToggle";

function ResetPassword() {
    const appConfig = getConfigForDomain(process.env.REACT_APP_DOMAIN);
    const {t} = useTranslation();
    const [username, setUsername] = useState('');
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [codeSent, setCodeSent] = useState(false);
    const [type, setType] = useState('password');

    const onChangeUsernameHandler = (event) => {
        setUsername(event.target.value);
        setShowErrorMessage(false);
    };

    const onChangeCodeHandler = (event) => {
        setCode(event.target.value);
        setShowErrorMessage(false);
    };

    const onChangePassHandler = (event) => {
        setPassword(event.target.value);
        setShowErrorMessage(false);
    };

    const passReset = async (event) => {
        event.preventDefault(); // Prevents default form submission behavior

        try {
            setShowLoader(true);
            await Auth.forgotPassword(username);
            setCodeSent(true);
            setShowLoader(false);
        } catch (error) {
            setShowErrorMessage(true);
            setErrorMsg(error.message);
            setShowLoader(false);
        }
    };

    const passSubmit = async (event) => {
        event.preventDefault();

        try {
            setShowLoader(true);
            await Auth.forgotPasswordSubmit(username, code, password);
            setCodeSent(false);
            setShowLoader(false);
            window.location.replace('/login');
        } catch (error) {
            setShowErrorMessage(true);
            setErrorMsg(error.message);
            setShowLoader(false);
        }
    }

    return (
        <form onSubmit={code ? passSubmit : passReset}>
            <div className="formBox">
                {showErrorMessage && errorMsg ? (
                    <p className="message login_error">
                        <strong>{t('Error')}: </strong>
                        {errorMsg}
                    </p>
                ) : (
                    <p className="message" style={{borderColor: appConfig.palette['main-color']}}>
                        {t('Register Message')}
                    </p>
                )}
                <div className="form">
                    <div className="formInputBox">
                        <label htmlFor="username" className="formLabel">
                            {t('Email')}
                        </label>
                        <input
                            type="text"
                            id="username"
                            className="formInput"
                            value={username}
                            onChange={onChangeUsernameHandler}
                        />
                    </div>
                    {codeSent && (
                        <>
                            <div className="formInputBox">
                                <label htmlFor="code" className="formLabel">
                                    {t('Verification code')}
                                </label>
                                <input
                                    type="text"
                                    id="code"
                                    className="formInput"
                                    value={code}
                                    onChange={onChangeCodeHandler}
                                />
                            </div>
                            <div className="formInputBox">
                                <label htmlFor="pass" className="formLabel">
                                    {t('New password')}
                                </label>
                                <input
                                    type={type}
                                    id="pass"
                                    className="formInput"
                                    value={password}
                                    onChange={onChangePassHandler}
                                />
                                <PasswordToggle type={type} setType={setType} />
                            </div>
                        </>
                    )}
                    <div>
                        <button
                            type="submit"
                            onClick={code ? passSubmit : passReset}
                            className={showLoader ? 'submitButton w-100 submitButtonFlex' : 'submitButton w-100'}
                            style={{backgroundColor: appConfig.palette['main-color']}}
                            disabled={showLoader}
                        >
                            {showLoader ? (
                                <Loader dimensions={{width: '20px', height: '20px'}}/>
                            ) : (
                                t('Reset Password')
                            )}
                        </button>
                    </div>
                </div>
                <div className="formBackLink">
                    <Link to="/login" style={{color: appConfig.palette['main-color']}}>
                        {t('Log in')}
                    </Link>
                    <span> | </span>
                    <Link to="/register" style={{color: appConfig.palette['main-color']}}>
                        {t('Register')}
                    </Link>
                </div>
            </div>
        </form>
    );
}

export default ResetPassword;
