import {useEffect, useState} from "react";
import './style.css';
import Title from "../../components/Title";
import getUsersList from "../../helpers/getUsersList";
import formatUserDataForBraze from "../../helpers/formatUserDataForBraze";
import {API} from "aws-amplify";
import {useTranslation} from "react-i18next";
import getConfigForDomain from "../../helpers/getConfigForDomain";

function AdminPage() {
    const { t } = useTranslation();
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [msg, setMsg] = useState('');
    const appConfig = getConfigForDomain(process.env.REACT_APP_DOMAIN);

    useEffect(() => {
            async function fetchUsers() {
                // Call the getUsersList function and await its result
                const result = await getUsersList('Members', process.env.REACT_APP_USER_POOL_ID);

                // Update state based on the result
                setUsers(result.users);
                setIsLoading(result.isLoading);
                setMsg(result.msg);
            }

            fetchUsers(); // Call the async function to fetch and set the data
    }, []);

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        },
        body: {
            recipients: formatUserDataForBraze(users),
        }
    };

    const sendBrazeRequest = async () => {
        try {
            await API.post('picksenseiAPI', '/send-braze-notifications', requestOptions);
            setMsg('Messages were sent');
            setTimeout(() => {
                setMsg('');
            }, 5000);
        } catch (error) {
            setMsg("Error sending Braze request:" + error);
        }
    };

    return (
        <>
            <Title text="Admin Page" headingLevel="1"></Title>
            <div className="NotificationsSendBlock">
                <button className="submitButton" onClick={sendBrazeRequest} disabled={isLoading} style={{backgroundColor: appConfig.palette['main-color']}}>{t("Send notifications to premium users")}</button>
                {msg && (
                    <span className="NotificationsSendBlockAlert">{msg}</span>
                )}
            </div>
        </>
    )
}

export default AdminPage;
