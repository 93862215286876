function findObjectKey(object, find) {
    let foundKey = null;
    for (const key in object) {
        if (object[key].includes(find)) {
            foundKey = key;
            break;
        }
    }
    return foundKey;
}

export default findObjectKey;
