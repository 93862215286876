import { createSlice } from '@reduxjs/toolkit';

const picksSlice = createSlice({
    name: 'picks',
    initialState: {},
    reducers: {
        updatePicks: (state, action) => {
            return action.payload; // Update the state with the new array
        }
    },
});

export const { updatePicks} = picksSlice.actions;
export default picksSlice.reducer;
