import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isDatastoreReady: false,
};

const dataStoreSlice = createSlice({
    name: 'dataStore',
    initialState,
    reducers: {
        setDatastoreReadyStatus(state, action) {
            state.isDatastoreReady = action.payload;
        },
    },
});

export const { setDatastoreReadyStatus } = dataStoreSlice.actions;
export default dataStoreSlice.reducer;
