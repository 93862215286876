import {API} from "aws-amplify";
import FetchUser from "../views/Auth";

async function getUsersList(groupName, userPoolId) {
    const user = FetchUser;
    // Define the request parameters, including the group name and user pool ID
    const requestParams = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: user.signInUserSession.idToken.jwtToken,
        },
        queryStringParameters: {
            GroupName: groupName, // Replace with the name of your Cognito group
            UserPoolId: userPoolId, // Replace with your Cognito User Pool ID
        },
    };

    try {
        const usersInGroup = await API.get('picksenseiAPI', '/list-users-in-group', requestParams);

        if (Array.isArray(usersInGroup) && usersInGroup.length > 0) {
            const premiumUsers = usersInGroup.filter((user) => {
                const membershipAttribute = user.Attributes.find((attr) => attr.Name === "custom:membership");
                return membershipAttribute && membershipAttribute.Value === "premium";
            });

            if (premiumUsers.length > 0) {
                return {users: premiumUsers, isLoading: false, msg: ''};
            } else {
                return {msg: "No users with 'premium' membership found."};
            }
        } else if (usersInGroup.message) {
            return {msg: 'Error listing users in group: ' + usersInGroup.message};
        } else {
            return {msg: 'No users found.'};
        }
    } catch (error) {
        return {msg: 'Error fetching users: ' + error};
    }
}

export default getUsersList;
