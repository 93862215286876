import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {updatePersonas} from "../../store/personas/personasSlice";
import isObjectEmpty from "../../helpers/isObjectEmpty";

const usePersona = () => {
    const dispatch = useDispatch();
    const picks = useSelector((state) => state.picks);
    const personas = useSelector((state) => state.personas);

    useEffect(() => {
        getPersona();
    }, [picks]);

    function getPersona(){
        if (isObjectEmpty(picks)) {
            return [];
        } else {
            const personaNames = [];

            for (const sportName in picks) {
                const sportEvents = picks[sportName];

                sportEvents.forEach(event => {
                    const personaName = event?.persona?.name;
                    if (personaName && !personaNames.includes(personaName)) {
                        personaNames.push(personaName);
                    }
                });
            }

            if (personaNames) {
                dispatch(updatePersonas(personaNames));
            }
        }
    }

    return personas;
};

export default usePersona;
