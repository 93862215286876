function getSportsWithLeagues(picks) {
    const leaguesBySport = {};

    for (const sportName in picks) {
        const sportEvents = picks[sportName];
        leaguesBySport[sportName] = [...new Set(sportEvents.map(event => event.league.name))];
    }

    return leaguesBySport;
}

export default getSportsWithLeagues;
