import {API} from "aws-amplify";

async function createStripeCheckoutSession(activeStripeID, user, localization, isWidget = false) {
    const clientID = isWidget ? user.id : user?.attributes?.sub;
    const clientEmail = isWidget ? user.email : user?.attributes?.email;

    const requestData = {
        headers: {"Content-Type": "application/json"},
        body: {"priceId": activeStripeID, "clientId": clientID, "email": clientEmail, "locale": localization}
    }

    await API.post('picksenseiAPI', '/create-checkout-session', requestData).then(({url}) => {
        // On success redirect the customer to the returned URL
        window.location = url
    }).catch(e => {
        console.error(e.error)
    });
}

export default createStripeCheckoutSession;
