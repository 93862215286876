async function fetchModels(item) {
    try {
        const models = await Promise.all([item.League, item.Persona, item.Sport]);
        const [league, persona, sport] = models;
        return {...item, league, persona, sport};
    } catch (error) {
        console.error('Error querying records:', error);
    }
}

export default fetchModels;
