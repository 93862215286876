/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://olhjmqmch5h2tcgkr73wmyxbtq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-6h7ap2bzhbf23ccrqdrxfegot4",
    "aws_cloud_logic_custom": [
        {
            "name": "picksenseiAPI",
            "endpoint": "https://fyjzl7q1b6.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:46906539-3e97-4a7d-a4c9-742957d77c84",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_jOcXPhnTA",
    "aws_user_pools_web_client_id": "499f07drhc9vponh1sq7veiho0",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "LOCALE",
        "UPDATED_AT"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "picksensei-storage-a2b5ce73104520-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
