// import useTerms from "../../hooks/useTerms/useTerms";
// import {Storage} from "@aws-amplify/storage";
import './style.css';
import Title from "../../components/Title";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {useEffect} from "react";
// import Loader from "../../components/Loader";
import getConfigForDomain from "../../helpers/getConfigForDomain";

function TermsAndConditions() {
    // const {files, isLoading} = useTerms();
    const {t} = useTranslation();
    const {pathname} = useLocation();
    const appConfig = getConfigForDomain(process.env.REACT_APP_DOMAIN);

    const filesList = [{
        name: 'Ansvarsfriskrivning.pdf',
        link: "https://picksensei-storage-a2b5ce73104520-staging.s3.amazonaws.com/public/t-c/Ansvarsfriskrivning.pdf"
    }, {
        name: 'Användarvillkor.pdf',
        link: 'https://picksensei-storage-a2b5ce73104520-staging.s3.amazonaws.com/public/t-c/Anv%C3%A4ndarvillkor.pdf'
    }, {
        name: 'Integritetspolicy.pdf',
        link: 'https://picksensei-storage-a2b5ce73104520-staging.s3.amazonaws.com/public/t-c/Integritetspolicy.pdf'
    }]

    // async function downloadFile(fileKey) {
    //     try {
    //         const signedUrl = await Storage.get(fileKey, {expires: 60}); // Adjust expiration time as needed
    //         // Use the signed URL to allow the user to download the file
    //         window.open(signedUrl);
    //     } catch (error) {
    //         console.error('Error downloading file:', error);
    //     }
    // }

    useEffect(() => {
        const element = document.documentElement;
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [pathname]);

    return (
        <div className="termsBox">
            <Title text="Terms and conditions" headingLevel="1"/>
            <div className="disclaimer">
                {t("Terms disclaimer")}
            </div>
            <ul className="fileList">
                {filesList.map((item, index) => (
                    <li key={index}>
                        <a href={item.link} className="downloadFile" target="_blank" style={{color: appConfig.palette['main-color']}}>{item.name}</a>
                    </li>
                ))}
            </ul>
            {/*{isLoading ? (*/}
            {/*    <Loader dimensions={{width: "20px", height: "20px"}}/>*/}
            {/*) : (*/}
            {/*    <ul className="fileList">*/}
            {/*        {files && files.map((file, index) => (*/}
            {/*            <li key={index}>*/}
            {/*                <span onClick={() => downloadFile(file.key)}*/}
            {/*                      className="downloadFile">{file.key.split('/').pop()}</span>*/}
            {/*            </li>*/}
            {/*        ))}*/}
            {/*    </ul>*/}
            {/*)}*/}
        </div>
    );
}

export default TermsAndConditions;
