import { createSlice } from '@reduxjs/toolkit';

const stripeSlice = createSlice({
    name: 'stripe',
    initialState: {clientSecret: null},
    reducers: {
        updateSecret: (state, action) => {
            state.clientSecret = action.payload;
        },
    },
});

export const { updateSecret } = stripeSlice.actions;
export default stripeSlice.reducer;
