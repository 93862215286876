import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Link, useSearchParams } from 'react-router-dom';
import '../../public/styles/forms.css';
import createStripeCheckoutSession from '../../helpers/createStripeCheckoutSession';
import { useTranslation } from 'react-i18next';
import getConfigForDomain from '../../helpers/getConfigForDomain';
import Loader from '../../components/Loader';
import PasswordToggle from "../../components/PasswordToggle";

function SignupButton() {
    const appConfig = getConfigForDomain(process.env.REACT_APP_DOMAIN);
    const { t } = useTranslation();
    const [userData, setUserData] = useState({
        username: '',
        showErrorMessage: false,
        errorMsg: '',
        password: '',
    });
    const [showLoader, setShowLoader] = useState(false);
    const [type, setType] = useState('password');

    const [searchParams] = useSearchParams();
    const origin = searchParams.get('origin') || null;
    const stripeId = searchParams.get('stripeID') || null;
    const isWidget = searchParams.get('isWidget') || null;

    const { username, showErrorMessage, errorMsg, password } = userData;

    const onChangeHandler = (field, value) => {
        setUserData((prevUserData) => ({
            ...prevUserData,
            [field]: value,
            showErrorMessage: false,
        }));
    };

    const signUp = async (event) => {
        event.preventDefault(); // Prevents default form submission behavior

        try {
            setShowLoader(true);
            const { user, userSub } = await Auth.signUp({
                username,
                password,
                attributes: {
                    locale: appConfig.localization,
                    updated_at: Date.now().toString(),
                    'custom:membership': 'member',
                    'custom:signupSource': origin || window.location.hostname,
                    'custom:isWidgetSubscribe': isWidget || 'false',
                    'custom:stripeCustomerID': '-',
                },
                autoSignIn: {
                    enabled: true,
                },
            });

            if (user && userSub && stripeId) {
                const widgetUser = {
                    email: user?.username,
                    id: userSub,
                };

                await createStripeCheckoutSession(
                    stripeId,
                    widgetUser,
                    appConfig.localization,
                    true
                );
            } else {
                setTimeout(() => {
                    window.location.replace('/');
                }, process.env.REACT_APP_TIMEOUT_TIME || 2000);
            }
        } catch (error) {
            setUserData((prevUserData) => ({
                ...prevUserData,
                showErrorMessage: true,
                errorMsg: error.message,
            }));
            setShowLoader(false);
        }
    };

    return (
        <form onSubmit={signUp}>
            <div className="formBox">
                {showErrorMessage && errorMsg ? (
                    <p className="message login_error">
                        <strong>{t('Error')}: </strong>
                        {errorMsg}
                    </p>
                ) : (
                    <p className="message" style={{ borderColor: appConfig.palette['main-color'] }}>
                        {t('Register For This Site')}
                    </p>
                )}
                <div className="form">
                    <div className="formInputBox">
                        <label htmlFor="username" className="formLabel">
                            {t('Email')} *
                        </label>
                        <input
                            type="text"
                            id="username"
                            className="formInput"
                            value={username}
                            onChange={(e) => onChangeHandler('username', e.target.value)}
                            required
                        />
                    </div>
                    <div className="formInputBox">
                        <label htmlFor="password" className="formLabel">
                            {t('Password')} *
                        </label>
                        <input
                            type={type}
                            id="password"
                            className="formInput"
                            value={password}
                            onChange={(e) => onChangeHandler('password', e.target.value)}
                            required
                        />
                        <PasswordToggle type={type} setType={setType} />
                    </div>
                    <div>
                        <button
                            type="submit"
                            onClick={signUp}
                            className={showLoader ? 'submitButton w-100 submitButtonFlex' : 'submitButton w-100'}
                            style={{ backgroundColor: appConfig.palette['main-color'] }}
                            disabled={showLoader}
                        >
                            {showLoader ? (
                                <Loader dimensions={{ width: '20px', height: '20px' }} />
                            ) : (
                                t('Create User')
                            )}
                        </button>
                    </div>
                </div>
                <div className="formBackLink">
                    <Link to="/login" style={{ color: appConfig.palette['main-color'] }}>
                        {t('Log in')}
                    </Link>
                    <span> | </span>
                    <Link to="/reset" style={{ color: appConfig.palette['main-color'] }}>
                        {t('Lost your password?')}
                    </Link>
                </div>
            </div>
        </form>
    );
}

export default SignupButton;
