import { createSlice } from '@reduxjs/toolkit';

const sportsSlice = createSlice({
    name: 'sports',
    initialState: [],
    reducers: {
        updateSports: (state, action) => {
            return action.payload; // Update the state with the new array
        },
    },
});

export const { updateSports } = sportsSlice.actions;
export default sportsSlice.reducer;
