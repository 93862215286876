import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import isObjectEmpty from "../../helpers/isObjectEmpty";
import getSportsWithLeagues from "../../helpers/getSportsWithLeagues";
import {updateLeagues} from "../../store/leagues/leaguesSlice";

const useLeague = () => {
    const dispatch = useDispatch();
    const picks = useSelector((state) => state.picks);
    const leagues = useSelector((state) => state.leagues);

    useEffect(() => {
        getLeagues();
    }, [picks]);

    function getLeagues() {
        if (isObjectEmpty(picks)) {
            return [];
        } else {
            const sportsWithLeagues = getSportsWithLeagues(picks);
            dispatch(updateLeagues(sportsWithLeagues));
        }
    }

    return leagues;
}

export default useLeague;
