import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import filterData from "../../helpers/filterPicks";

const useFilterPicks = (picks) => {
    const [filteredPicks, setFilteredPicks] = useState({});
    const filters = useSelector((state) => state.filters);

    useEffect(() => {
        setFilteredPicks(filterData(picks, filters));
    }, [filters, picks]);

    return filteredPicks;
};
export default useFilterPicks;
