import { configureStore } from '@reduxjs/toolkit';
import picksReducer from "./picks/picksSlice";
import sportsReducer from "./sports/sportsSlice";
import leaguesReducer from "./leagues/leaguesSlice";
import personasReducer from "./personas/personasSlice";
import filtersReducer from "./filters/filtersSlice";
import picksHistoryReducer from "./picksHistory/picksHistorySlice";
import stripeReducer from './stripe/stripeSlice';
import datastoreReducer from './dataStore/dataStoreSlice';

export default configureStore({
    reducer: {
        picks: picksReducer,
        sports: sportsReducer,
        leagues: leaguesReducer,
        personas: personasReducer,
        filters: filtersReducer,
        picksHistory: picksHistoryReducer,
        stripe: stripeReducer,
        dataStore: datastoreReducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({ immutableCheck: false, serializableCheck: false });
    },
});
