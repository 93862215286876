import { createSlice } from '@reduxjs/toolkit';

const picksHistorySlice = createSlice({
    name: 'picksHistory',
    initialState: {},
    reducers: {
        updatePicksHistory: (state, action) => {
            return action.payload; // Update the state with the new array
        }
    },
});

export const { updatePicksHistory} = picksHistorySlice.actions;
export default picksHistorySlice.reducer;
