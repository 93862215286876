import getConfigForDomain from "../../helpers/getConfigForDomain";
import './index.css';
import {useTranslation} from "react-i18next";
import linkParser from "../../helpers/linkParser";

function Footer() {
    const appConfig = getConfigForDomain(process.env.REACT_APP_DOMAIN);
    const isFooterActive = appConfig.isFooterActive;
    const footerContent = appConfig.footerContent;
    const currentYear = new Date().getFullYear();
    const { t } = useTranslation();
    const parse = require('html-react-parser');

    return (
        <>
            {isFooterActive ? (
                <footer style={{borderColor: appConfig.palette["border-color"], backgroundColor: appConfig.palette["footer-bg-color"]}}>
                    <div className="container">
                        {footerContent.length && (
                            <div>
                                {parse(footerContent, {
                                    replace: (node) => linkParser.replace(node),
                                })}
                            </div>
                        )}
                        <p className="copyright">
                            {t("Copyright")} &copy; {currentYear} {window.location.hostname}
                        </p>
                    </div>
                </footer>
            ) : null}
        </>
    )
}

export default Footer;
