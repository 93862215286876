import './index.css';
import {useTranslation} from "react-i18next";
import getConfigForDomain from "../../helpers/getConfigForDomain";
function ToggleButton ({isActive, text, onClick}) {
    const appConfig = getConfigForDomain(process.env.REACT_APP_DOMAIN);
    const { t } = useTranslation();
    return (
        <button className={isActive ? 'toggleButton active' : 'toggleButton'} onClick={onClick} style={isActive ? {backgroundColor: appConfig.palette['main-color'], borderColor: appConfig.palette['main-color']} : {}}>
            {t(text)}
        </button>
    );
}

export default ToggleButton;
