import * as React from "react";
import {BrowserRouter} from "react-router-dom";
import './App.css';
import NavBar from "./components/Navigation";
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";
import {HelmetProvider} from 'react-helmet-async';
import SEO from "./components/SEO";

function App() {
    return (
        <I18nextProvider i18n={i18n}>
            <HelmetProvider>
                <SEO/>
                <div className="App">
                    <BrowserRouter>
                        <NavBar/>
                    </BrowserRouter>
                </div>
            </HelmetProvider>
        </I18nextProvider>
    );
}

export default App;
