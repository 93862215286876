import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import isObjectEmpty from "../../helpers/isObjectEmpty";
import {updateSports} from "../../store/sports/sportsSlice";
import getSportsWithLeagues from "../../helpers/getSportsWithLeagues";

const useSport = () => {
    const dispatch = useDispatch();
    const picks = useSelector((state) => state.picks);
    const sports = useSelector((state) => state.sports);

    useEffect(() => {
        getSports();
    }, [picks]);

    function getSports() {
       if (isObjectEmpty(picks)) {
           return [];
       } else {
           const sportsWithLeagues = getSportsWithLeagues(picks);
           dispatch(updateSports(Object.keys(sportsWithLeagues)));
       }
    }

    return sports;
}

export default useSport;
