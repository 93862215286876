import usePicks from "../../hooks/usePicks";
import {updatePicksHistory} from "../../store/picksHistory/picksHistorySlice";
import PicksLayout from "../PicksLayout";
import Loader from "../Loader";

function PicksHistory({user}) {
    const [sportNames, picks, isLoading] = usePicks('history', user, updatePicksHistory, false);

    return (
        <>
            {isLoading ? (
                <Loader dimensions={{width: "20px", height: "20px"}}/>
            ) : (
                <>
                    {sportNames && sportNames.length > 0 && (
                        sportNames.map((title, index) => {
                            return <PicksLayout sportName={title} picks={picks[index]} user={user} key={index} isHistory={true}/>
                        })
                    )}
                </>
            )}
        </>
    )
}

export default PicksHistory;
