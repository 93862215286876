import './style.css';
import capitalizeFirst from "../../helpers/capitalizeFirst";
import useExpertData from "../../hooks/useExpertData";
import StatisticTable from "../StatisticTable";

function ExpertTable({name}) {
    const [statisticsBySport, overallStatistics, imageUrl] = useExpertData(capitalizeFirst(name));

    return (
        <StatisticTable name={name} statisticsBySport={statisticsBySport} overallStatistics={overallStatistics} imageURL={imageUrl}/>
    )
}

export default ExpertTable;
