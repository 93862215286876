import { setDatastoreReadyStatus } from '../dataStore/dataStoreSlice'; // Import the slice action
import checkDatastoreReady from '../../helpers/checkDatastoreReady'; // Import your checkDatastoreReady function

export const initializeDataStore = () => async (dispatch) => {
    try {
        const { isDatastoreReady } = await checkDatastoreReady();

        // Dispatch action from slice to update Redux state
        dispatch(setDatastoreReadyStatus(isDatastoreReady));
    } catch (error) {
        // Handle error, dispatch action, or perform necessary steps
        console.error('Error initializing DataStore:', error);
    }
};
