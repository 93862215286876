import fetchModels from "./fetchRelatedModels";
import groupBySport from "./groupPicksBySport";

async function formatStatisticData(picks) {
    let result = [];

    if (picks.length) {
        const resArr = await Promise.all(picks.map(fetchModels));
        const picksGroupedBySport = groupBySport(resArr);
        const statisticsBySport = {};
        const overallStatistics = {
            winCount: 0,
            loseCount: 0,
            oddsSum: 0,
            voidCount: 0
        };

        for (const sport in picksGroupedBySport) {
            statisticsBySport[sport] = {
                winCount: 0,
                loseCount: 0,
                voidCount: 0,
                oddsSum: 0,
            };

            // Iterate through each pick in the current sport category
            picksGroupedBySport[sport].forEach(pick => {
                // Convert odds to a floating-point number
                const odds = pick.won[0] === 'WON' ? parseFloat(pick.odds) - 1 : 1;

                // Check the value of pick.won[0] using a switch statement
                switch (pick.won[0]) {
                    case 'WON':
                        overallStatistics.winCount++;
                        overallStatistics.oddsSum += odds;
                        statisticsBySport[sport].winCount++;
                        statisticsBySport[sport].oddsSum += odds;
                        break;
                    case 'LOST':
                        overallStatistics.loseCount++;
                        overallStatistics.oddsSum -= odds;
                        statisticsBySport[sport].loseCount++;
                        statisticsBySport[sport].oddsSum -= odds;
                        break;
                    case 'VOID':
                        statisticsBySport[sport].voidCount++;
                        overallStatistics.voidCount++;
                        break;
                    default:
                        break;
                }
            });
        }

        result = [statisticsBySport, overallStatistics];
    }

    return result;
}

export default formatStatisticData;
