import {createSlice} from '@reduxjs/toolkit';

// Helper function to find an existing filter by name and relatedSport in a filterArray
const findExistingFilter = (filterArray, name, relatedSport) =>
    filterArray.find(filter => filter.name === name && filter.relatedSport === relatedSport);

// Helper function to update the checked state of a filter in a filterArray
const updateFilterCheckedState = (filterArray, name, checked) => {
    const filter = filterArray.find(filter => filter.name === name);
    if (filter) {
        filter.checked = checked;
    }
};

const initialFiltersState = {
    sports: [],
    leagues: [],
    experts: []
};

const filtersSlice = createSlice({
    name: 'filters',
    initialState: initialFiltersState,
    reducers: {
        addFilters: (state, action) => {
            const {type, name, checked, relatedSport, relatedLeagues} = action.payload;

            if (type === 'sport') {
                const existingSport = findExistingFilter(state.sports, name);
                if (existingSport) {
                    updateFilterCheckedState(state.sports, name, checked);
                    relatedLeagues.forEach(leagueName => {
                        updateFilterCheckedState(state.leagues, leagueName, checked);
                    });
                } else {
                    state.sports.push({name, checked, relatedLeagues});
                    relatedLeagues.forEach(league => {
                        if (!findExistingFilter(state.leagues, league, name)) {
                            state.leagues.push({name: league, checked: false, relatedSport: name});
                        }
                    });
                }
            } else if (type === 'league') {
                const existingLeague = findExistingFilter(state.leagues, name, relatedSport);
                if (existingLeague) {
                    updateFilterCheckedState(state.leagues, name, checked);

                    // Update the checked state for the related sport
                    const relatedSportFilter = state.sports.find(sport => sport.name === relatedSport);
                    if (relatedSportFilter) {
                        relatedSportFilter.checked = state.leagues.some(leagueFilter => leagueFilter.relatedSport === relatedSport && leagueFilter.checked);
                    }
                }
            } else if (type === 'expert') {
                const existingExpert = findExistingFilter(state.experts, name);
                if (existingExpert) {
                    updateFilterCheckedState(state.experts, name, checked);
                } else {
                    state.experts.push({name, checked});
                }
            }
        },
        removeFilters: (state, action) => {
            const {type, name, relatedSport, relatedLeagues} = action.payload;

            const uncheckFilter = (filterArray, filterName) => {
                const filter = filterArray.find(filter => filter.name === filterName);
                if (filter) {
                    filter.checked = false;
                }
            };

            if (type === 'sport') {
                uncheckFilter(state.sports, name);

                relatedLeagues.forEach(leagueName => {
                    uncheckFilter(state.leagues, leagueName);
                });
            } else if (type === 'league') {
                uncheckFilter(state.leagues, name);

                const relatedSportLeagues = state.leagues.filter(filter => filter.relatedSport === relatedSport);
                const allLeaguesUnchecked = relatedSportLeagues.every(filter => !filter.checked);

                if (allLeaguesUnchecked) {
                    uncheckFilter(state.sports, relatedSport);
                }
            } else if (type === 'expert') {
                uncheckFilter(state.experts, name);
            }
        },
        resetFilters: () => initialFiltersState,
    },
});

export const {addFilters, removeFilters, resetFilters} = filtersSlice.actions;
export default filtersSlice.reducer;
