import './index.css';

function Modal({ setModalIsOpen = () => {}, children }) {
    return (
        <>
            {children ? (
                <div className="darkBG" onClick={() => setModalIsOpen(false)}>
                    <div className="modalCentered">
                        <div className="modalWindow">
                            {children}
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    )
}

export default Modal;
