import { createSlice } from '@reduxjs/toolkit';

const personasSlice = createSlice({
    name: 'personas',
    initialState: {},
    reducers: {
        updatePersonas: (state, action) => {
            return action.payload; // Update the state with the new array
        },
    },
});

export const { updatePersonas } = personasSlice.actions;
export default personasSlice.reducer;
