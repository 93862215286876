import {Helmet} from "react-helmet-async";
import getConfigForDomain from "../../helpers/getConfigForDomain";

function SEO() {
    const appConfig = getConfigForDomain(process.env.REACT_APP_DOMAIN);

    return (
        <Helmet>
            <meta
                name="description"
                content={appConfig.description}
            />
            { /* Facebook tags */ }
            <meta property="og:title" content={appConfig.title}/>
            <meta property="og:description" content={appConfig.description}/>
            <meta property="og:type" content="website"/>
            <meta property="og:url" content={window.location.href}/>
            { /* End Facebook tags */ }
            { /* Twitter tags */ }
            <meta name="twitter:creator" content="Picksensei" />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={appConfig.title} />
            <meta name="twitter:description" content={appConfig.description} />
            { /* End Twitter tags */ }
            <link rel="icon" href={'/' + appConfig.favicon + '.ico'} sizes="16x16"/>
            <title>{appConfig.title}</title>
        </Helmet>
    )
}

export default SEO;
