import Title from "../../components/Title";
import ToggleButton from "../../components/ToggleButton";
import DropdownList from "../../components/DropdownList";
import './index.css';
import {useEffect, useState} from "react";
import useSport from "../../hooks/useSport/useSport";
import useLeague from "../../hooks/useLeague";
import usePersona from "../../hooks/usePersona";
import FetchUser from "../Auth";
import PicksHistory from "../../components/PicksHistory";
import PicksActive from "../../components/PicksActive";
import {Link, useLocation} from "react-router-dom";
import {t} from "i18next";

function Home() {
    const {pathname} = useLocation();
    const user = FetchUser;
    const [activeButton, setActiveButton] = useState('Active picks');
    const handleButtonClick = (buttonId) => {
        setActiveButton(buttonId);
    };

    const sports = useSport();
    const leagues = useLeague();
    const personas = usePersona();

    useEffect(() => {
        const element = document.documentElement;
        if (element) {
            setTimeout(function () {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }, 10);
        }
    }, [pathname]);

    return (
        <>
            <Title text="Picks" headingLevel="2"/>
            <ToggleButton isActive={activeButton === 'Active picks'} text="Active picks"
                          onClick={() => handleButtonClick('Active picks')}/>
            <ToggleButton isActive={activeButton === 'Pick History'} text="Pick History"
                          onClick={() => handleButtonClick('Pick History')}/>
            <Link to='/experts' className="expertsLink">{t('Experts records')}</Link>
            <Link to='/about-us' className="expertsLink">{t('About us')}</Link>
            <div className="filtersRow">
                <DropdownList title="sport" data={sports}/>
                <DropdownList title="league" data={leagues}/>
                <DropdownList title="expert" data={personas}/>
            </div>
            <PicksActive activeButton={activeButton} user={user}/>
            {activeButton !== 'Pick History' && (
                <>
                    <Title text="Pick History" additionalStyles={{marginTop: '32px'}} headingLevel="2"/>
                    <PicksHistory user={user}/>
                </>
            )}
        </>
    );
}

export default Home;
